import * as React from "react"
import Layout from "../components/layout/Layout"

import Estandares from "../components/estandar/Estandares"
// markup
const IndexPage = () => {
  return (
<Layout>
  <Estandares/>
</Layout>
  )
}

export default IndexPage
